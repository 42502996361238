<template>
	<div id="app">
		 <b-card-group>
          <b-card  header="" header-tag="header" footer-tag="footer" title="">
               <b-row>
                <b-col sm="3">
                     <CInput
                        label="From Date"
                        type="date"
                        vertical
                        v-model="fromDate"
                        @input="startDateOnChange()"
                      />
                </b-col>
                <b-col sm="3">
                    <CInput
                        label="To Date"
                        type="date"
                        vertical
                        v-model="toDate"
                        @input="endDateOnChange()"
                      />
                </b-col>
                <b-col sm="3">
                    <div class="form-group">
                        <b-row>
                        <b-col sm="6">
                        <label for="input-small" >Status</label>
                        </b-col>
                        <b-col sm="6">
                        <b-form-checkbox
                          id="cbstatus"
                          v-model="cbstatus"
                          name="cbstatus"
                          @change="onModifyClick"
                        >
                         All
                        </b-form-checkbox>
                        </b-col>
                        </b-row>
                          <multiselect v-bind="attrMSelect" @select="toggleStsSelected" @remove="toggleStsUnSelect" v-model="defOptSts" :options="optStatus" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"  label="name" track-by="name" :preselect-first="true">
                            <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                          </multiselect>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="form-group">
                        <label for="input-small" >Currency</label>
                        <multiselect @select="toggleCurrSelected" @remove="toggleCurrUnSelect" v-model="defOptCurr" :options="optCurrency" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                            <template slot="selection2" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                        </multiselect>
                    </div>
                </b-col>
               </b-row>

               <b-row>
                <b-col sm="4">
                     <div class="form-group">
                        <CInput
                            v-bind="attrsFrom"
                            type="number"
                            min='0'
                            @change="amtFromChange"
                            v-model="amountFrom"
                            :id="amountFrom"
                            label="Amount From"
                            required
                          />
                     </div>
                </b-col>
                <b-col sm="4">
                     <div class="form-group">
                        <CInput
                            v-bind="attrsTo"
                            type="number"
                            min='0'
                            @change="amtToChange"
                            v-model="amountTo"
                            :id="amountTo"
                            label="Amount To"
                            required
                            />
                     </div>
                </b-col>
                <b-col sm="4">
                     <div class="form-group">
                        <label for="input-small" >Type</label>
                        <b-form-select @change="typeChange" v-model="defoptType" :options="optType"></b-form-select>
                     </div>
                </b-col>
                <!--b-col sm="2">
                     <CInput
                        type="number"
                        min='1'
                        max='24'
                        @change="timeRangeChange"
                        v-model="timeRange"
                        :id="timeRange"
                        label="Time Range"
                        description="1 - 24 Hours"
                        required
                        was-validated
                        />
                </b-col>
                <b-col sm="2">
                    <div class="form-group">
                        <label for="input-small" >Start Time</label> <br/-->
                        <!--vue-timepicker @change="timeChange" v-model="time" format="HH:mm:ss"></vue-timepicker>
                        <vue-timepicker @change="timeChange" v-model="time" format="HH:mm" :minute-interval="10"></vue-timepicker>
                     </div>
                </b-col-->
               </b-row>

               <b-row>
                  <b-col sm="12">
                     <div class="form-group">
                        <label for="input-small" >Search</label>
                        <b-form-input v-model="filter" placeholder="Type to Search" :id="search"></b-form-input>
                     </div>
                  </b-col>
               </b-row>
           </b-card>
        </b-card-group><br/>

        <div class="table-responsive table">
           <b-table striped hover :items="items" :fields="fields" sort-by="transactionDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(topUpCode)="{ item }">
                {{ item.topUpCode | filterOnly }}
              </template>

               <template v-slot:cell(txnFee)="{ item }">
                 {{ item.txnFee | setValDefault }}
              </template>

               <template v-slot:cell(promoAmount)="{ item }">
                 {{ item.promoAmount | setValDefault }}
              </template>

              <template v-slot:cell(transactionDate)="{ item }">
                {{ item.transactionDate | dateSubstr }}
              </template>

              <template v-slot:cell(movementType)="{ item }">
                    {{ item.movementType | changeLabel(item.dbsTransactionType,item.paymentMethod) }}
              </template>

           </b-table>
         </div><br/>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>
        </div>

	</div>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import DateRangePicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
import VueTimepicker from 'vue2-timepicker';
import moment from 'moment';

 export default {
    name: "transaction",
    components: {
       Multiselect,
       VueTimepicker
    },
    data() { var date = new Date();

      return {
          time:'',
          timeRange:'',
          defOptSts:'',
          defOptCurr:'',
          cbstatus:false,
          attrsFrom: {
            description : ""
          },
          attrsTo: {
            description : ""
          },
          attrMSelect:{
            placeholder : ""
          },
          //fromDate:null,
          fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
          //toDate:null,
          toDate: new Date().toISOString().substr(0, 10),
          defoptType:null,
          optType:[
              { value: 'P2P', text: 'P2P'},
              { value: null, text: 'ALL'}
          ],
          optStatus: [
              { name: 'COMPLETED'},
              { name: 'CANCELED'},
              { name: 'REFUNDED'}
          ],
          baseOptStatus:[],
          optCurrency: [
                { name: 'SGD' },
                { name: 'IDR' },
                { name: 'USD' }
          ],
          items:[],
          type:[],
          status: [],
          currency:[],
          amountFrom:null,
          amountTo:null,
          search:null,
          fields: [
            {
               name : 'transactionId',
               key: 'transactionId',
               label : 'Transaction ID',
               sortable: true
            },
            {
               name : 'transactionDate',
               key: 'transactionDate',
               label : 'Transaction Date',
               sortable: true
            },
            {
               name : 'sender',
               key: 'sender',
               label : 'Received From',
               sortable: true
            },
            {
               name : 'senderPhone',
               key: 'senderPhone.number',
               label : 'Received Phone No.',
               sortable: true
            },
            {
               name : 'beneficiary',
               key: 'beneficiary',
               label : 'Sent To',
               sortable: true
            },
            {
               name : 'beneficiaryPhone',
               key: 'beneficiaryPhone.number',
               label : 'Sent Phone No.',
               sortable: true
            },
            {
               name : 'currency',
               key: 'currency',
               label : 'Currency',
               sortable: true
            },
            {
               name : 'amount',
               key: 'amount',
               label : 'Amount($)',
               sortable: true
            },
            {
               name : 'txnFee',
               key: 'txnFee',
               label : 'Tr. Fee',
               sortable: true
            },
            {
               name : 'promoAmount',
               key: 'promoAmount',
               label : 'Promo Amount',
               sortable: true
            },
            {
               name : 'movementType',
               key: 'movementType',
               label : 'Type',
               sortable: true
            },
            {
               name : 'status',
               key: 'status',
               label : 'Status',
               sortable: true
            }
          ],
          currentPage: 1,
          perPage: 10,
          filter: null,
         };
   },

   filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           value = value.split(".")
           return value[0].replace("T"," ")
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'
        },
        changeLabel(value,dbsTT,payM){
            if(!value) return ''
            if(dbsTT==='BUYNOW') return 'BUYNOW_SGQR'
            if(payM==='WALLET_IDR') return 'IDR_PAYNOW'
            else return value
        },
        filterOnly(value){
            if(!value) return ''
            if(value.substr(0,2)==='EW') return value;
            else return ''
        },
        setValDefault(value){
            if(!value) return 0
            else return value
        }
   },

    created () {
        //this.getUserDetail(this.$route.params.id);
        this.getConfigList();
    },
    methods: {

      onModifyClick(e) {
        if(e){
            //console.log('checked')
            this.attrMSelect.placeholder="ALL";
            this.defOptSts='';
            this.optStatus=[];
            this.status =[];
            this.retrieveTransac();
        } else {
            //console.log('unchecked')
            this.attrMSelect.placeholder="Pick Some";
            this.optStatus = this.baseOptStatus;
            this.retrieveTransac();
        }

      },

      sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {

            var currSrc=[];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ currSrc.push({ name : item}); }
            this.optCurrency = currSrc;

            var typeSrc=[];
            var arrTypes = resp.transactionType;
						typeSrc.push({ value: null  , text: 'ALL' });
            typeSrc.push({ value: 'WALLET_COIN_PHP'  , text: 'COIN_PHP' });
            typeSrc.push({ value: 'DBS_PAYNOW_DASH'  , text: 'DBS_PAYNOW_DASH' });
            typeSrc.push({ value: 'DBS_PAYNOW_GRAB'  , text: 'DBS_PAYNOW_GRAB' });
            typeSrc.push({ value: 'DBS_MCA'  , text: 'DBS_MCA' });
            for (const item2 of arrTypes) {
                if(item2!=="ADMIN") { typeSrc.push({ value: item2 , text: item2 }); }
            }
						typeSrc.push({ value: 'WALLET_GRAB_PHP'  , text: 'GRAB_PHP' });
            typeSrc.push({ value: 'IDR_PAYNOW'  , text: 'IDR_PAYNOW' });
            typeSrc.push({ value: 'PHP_PAYNOW'  , text: 'PHP_PAYNOW' });
            //typeSrc.push({ value: 'DBS_PAYNOW_PROXY'  , text: 'DBS_PAYNOW_PROXY' });
            typeSrc.push({ value: 'SGQR_BUYNOW'  , text: 'SGQR_BUYNOW' });
            typeSrc.push({ value: 'REDEEM'  , text: 'REDEEM' });
            typeSrc.sort(this.sortByProperty("text"));
            this.optType = typeSrc;

            var mvStsSrc=[];
            var arrMvSts = resp.movementStatus;
            for (const item3 of arrMvSts){ mvStsSrc.push({ name : item3 }); }

            this.optStatus = mvStsSrc;
            this.baseOptStatus = mvStsSrc;

           }, error => {
            console.log('error');
          });
      },

      getUserDetail: function(id) {
          AuthService.getUserDetail(id).then(resp => {


          }, error => {
            console.log('error');
          });
      },

     getRequestParams(params) {

        var term = this.time;
        var re = new RegExp("^[0-9:]*$");
        var isRemittance = ['IDR_PAYNOW','PHP_PAYNOW','DBS_MCA', 'WALLET_GRAB_PHP', 'WALLET_COIN_PHP'];
        var isDbsPaynow  = ['SGQR_BUYNOW','DBS_PAYNOW_DASH','DBS_PAYNOW_GRAB'];

        if(isRemittance.includes(this.defoptType)){
            var movementType = "REMITTANCE";
        } else if(isDbsPaynow.includes(this.defoptType)){
            var movementType = "DBS_PAYNOW";
        } else if(this.defoptType == 'REDEEM'){
            var movementType = "ADMIN";
        } else {
            var movementType = this.defoptType;
        }

        params["userId"] = this.$route.params.id;
        params["currency"] = this.currency;
        params["searchString"] = null;
        params["movementType"] = movementType;
        params["fromDate"] = this.fromDate+"T00:00:00";
        params["toDate"] = this.toDate+"T23:59:59";
        params["fromRange"] = this.amountFrom;
        params["toRange"] = this.amountTo;
        params["movementStatus"] = this.status;
        params["startTime"] = null; //(re.test(term)) ? term : null;
        params["timeRange"] = null; //this.timeRange;

        return params;
     },

     retrieveTransac(){
         this.spinner = true;  let params = {};  let items = [];
         this.getRequestParams(params);
         var idrPaynow = ["WALLET_IDR","WALLET_IDR_PROXY"];
         var phpPaynow = ["WALLET_PHP"];
         var proxyTypeList = ["NRIC","MOBILE","UEN"];
         var dbsMca = ["WALLET_DBS_MCA","MANUAL_DBS_MCA"];
         var remitWallet = ["WALLET_GRAB_PHP","WALLET_COIN_PHP"];

         AuthService.getUserTransact(params).then((resp) => { //console.log(resp);

            for (var key in resp) {
               var temp = resp[key];
                if(this.defoptType === "DBS_PAYNOW_DASH"){
                     if(temp.dbsTransactionType=='PAYNOW'  && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
                        temp.movementType = "DBS_PAYNOW_DASH"; items.push(temp);
                     }
                } else if (this.defoptType === "DBS_PAYNOW_GRAB"){
                     if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
                        temp.movementType = "DBS_PAYNOW_GRAB"; items.push(temp);
                     }
                } else if (this.defoptType === "IDR_PAYNOW"){
                  if(idrPaynow.includes(temp.paymentMethod)){
                       temp.movementType   ="IDR_PAYNOW"; items.push(temp);
                  }
                } else if (this.defoptType === "DBS_MCA"){
                  if(dbsMca.includes(temp.paymentMethod)){
                       var labelType = temp.paymentMethod.replace('_DBS_MCA','');
                       temp.movementType = labelType; items.push(temp);
                  }
                } else if (this.defoptType === "PHP_PAYNOW"){
                  if(phpPaynow.includes(temp.paymentMethod)){
                       temp.movementType = "PHP_PAYNOW"; items.push(temp);
                  }
                } else if (this.defoptType === "REMITTANCE"){
                   if(!idrPaynow.includes(temp.paymentMethod)
                      && !phpPaynow.includes(temp.paymentMethod)
                      && !dbsMca.includes(temp.paymentMethod)
											&& !remitWallet.includes(temp.paymentMethod)){
                       items.push(temp);
                   }
                } else if (this.defoptType === "WALLET_GRAB_PHP"){
										if(!idrPaynow.includes(temp.paymentMethod)
										 && !phpPaynow.includes(temp.paymentMethod)
										 && !dbsMca.includes(temp.paymentMethod)
										 && temp.paymentMethod === "WALLET_GRAB_PHP"){
												temp.movementType = "GRAB_PHP";
												temp.paymentMethod = "WALLET";
												items.push(temp);
										}
								} else if (this.defoptType === "WALLET_COIN_PHP"){
										if(!idrPaynow.includes(temp.paymentMethod)
										 && !phpPaynow.includes(temp.paymentMethod)
										 && !dbsMca.includes(temp.paymentMethod)
										 && temp.paymentMethod === "WALLET_COIN_PHP"){
												temp.movementType = "COIN_PHP";
												temp.paymentMethod = "WALLET";
												items.push(temp);
										}
								} else if (this.defoptType === "SGQR_BUYNOW"){
                  if(temp.dbsTransactionType=='BUYNOW'){
                       temp.movementType   = "SGQR_BUYNOW";  items.push(temp);
                  }
                } else if (this.defoptType === "DBS_PAYNOW"){
                  if(temp.dbsTransactionType=='PAYNOW' && proxyTypeList.includes(temp.dbsProxyType) ){
                       temp.movementType   = "DBS_PAYNOW"; items.push(temp);
                  }
                } else if (this.defoptType === "REDEEM"){
                   if(temp.systemTransactionType=='REDEEM'){
                       temp.movementType   = "REDEEM"; items.push(temp);
                   }
                } else {
                  if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
                        temp.movementType   ="DBS_PAYNOW_DASH";
                  }
                  else if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
                        temp.movementType   ="DBS_PAYNOW_GRAB";
                  }
                  else if(idrPaynow.includes(temp.paymentMethod)){
                       temp.movementType   ="IDR_PAYNOW";
                  }
                  else if(phpPaynow.includes(temp.paymentMethod)){
                       temp.movementType = "PHP_PAYNOW";
                  }
                  else if(dbsMca.includes(temp.paymentMethod)){
                       temp.movementType = 'DBS_MCA';
                  }
                  else if(!idrPaynow.includes(temp.paymentMethod)
                      && !phpPaynow.includes(temp.paymentMethod)
                      && !dbsMca.includes(temp.paymentMethod)
                      && !remitWallet.includes(temp.paymentMethod)
                      && temp.dbsTransactionType=='REMITTANCE'){
                        temp.movementType = temp.dbsTransactionType;
                  }
                  else if(temp.dbsTransactionType=='BUYNOW'){
                       temp.movementType   = "SGQR_BUYNOW";
                  }
                  else if(temp.dbsTransactionType=='PAYNOW' && proxyTypeList.includes(temp.dbsProxyType)){
                       temp.movementType   = "DBS_PAYNOW";
                  }
                  else if(temp.systemTransactionType=='REDEEM'){
                       temp.movementType   = "REDEEM";
                  }
									else if(temp.movementType == "REMITTANCE" && temp.paymentMethod == "WALLET_GRAB_PHP"){
												temp.movementType = "GRAB_PHP";
												temp.paymentMethod = "WALLET";
									}
									else if(temp.movementType == "REMITTANCE" && temp.paymentMethod == "WALLET_COIN_PHP"){
												temp.movementType = "COIN_PHP";
												temp.paymentMethod = "WALLET";
									}

                  items.push(temp);
                }
            }

            this.items   = items;
            this.spinner = false;
         }).catch((e) => { console.log(e); });
     },

     amtFromChange: function(){
          if(this.amountTo){
            this.attrsFrom.description= "";
            this.attrsTo.description= "";
            this.retrieveTransac();
          } else { this.attrsTo.description = "Amount To Required !" }
     },

     amtToChange: function () {
         if(this.amountFrom){
            this.attrsFrom.description= "";
            this.attrsTo.description= "";
            this.retrieveTransac();
         } else { this.attrsFrom.description = "Amount From Required !"  }
     },

     timeChange(){
         var term = this.time; //console.log(this.time);
         var re = new RegExp("^[0-9:]*$");
         if (re.test(term)) {
           this.retrieveTransac();
         }
     },

     timeRangeChange(){
        this.retrieveTransac();
     },

     searchChange(){
         this.retrieveTransac();
     },

     removedColField(keys){
       var cols = this.fields;  var rmkey = [];
       for (var key in cols) {
          var col = cols[key]; //console.log(col.key);
          if(keys.includes(col.key)){
               rmkey.push(key);
          }
       }

       var i = 1;
       for (var key2 in rmkey) {
          var val = rmkey[key2];
          var idx = (i===2) ? (val-1) : val;  //console.log(idx);
          cols.splice(idx,1);
          i++;
       }

       this.fields = cols;
     },

     typeChange(){
         var fields = []; var keys =[]; fields = this.fields;
         let col1  = {key:'paymentMethod',label:'Payment Method',sortable: true};
         let col2  = {key:'customerReference',label:'Customer Ref.',sortable: true};
         let col3  = {key:'topUpCode',label:'Top Up Code',sortable: true};
         let col4  = {key:'trsListingId',label:'Trs Listing ID',sortable: true};
         let col5  = {key:'message',label:'Reason',sortable: true};

         if(this.defoptType=="REMITTANCE" || this.defoptType=="WALLET_GRAB_PHP" || this.defoptType=="WALLET_COIN_PHP"){
             var finalObj = fields.concat(col1,col2);
             this.fields  = finalObj;  keys.push("topUpCode");
         } else if(this.defoptType=="TOP_UP"){
            var finalObj = fields.concat(col3); this.fields  = finalObj;
            keys.push("paymentMethod","customerReference");
         } else if(this.defoptType=="WECHAT_PAYNOW"){
            var finalObj = fields.concat(col4,col5);
            this.fields  = finalObj;
         } else {
            keys.push("paymentMethod","customerReference","topUpCode","trsListingId","message");
         }

         this.removedColField(keys);
         this.setColumns();
         this.retrieveTransac();
     },

     setColumns(){
        this.columns = [];
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {
           if(fields[key].key !== "index"){
             row['label'] = fields[key].label;
             row['field'] = fields[key].key;
             var temp = JSON.stringify(row); cols.push(temp);
           }
        }

        for (var key2 in cols) {
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
     },

     startDateOnChange(){
         this.retrieveTransac();
     },

     endDateOnChange(){
         this.retrieveTransac();
     },

     toggleStsSelected(value) {
         if(!this.status.includes(value.name)){
             this.status.push(value.name);
             this.retrieveTransac();
         }

     },

     toggleStsUnSelect(value, id){
         for( var i = 0; i < this.status.length; i++){
              if ( this.status[i] === value.name) {
                  this.status.splice(i, 1);
              }
          }

          this.retrieveTransac();
     },

     toggleCurrSelected(value) {
         if(!this.currency.includes(value.name)){
            this.currency.push(value.name);
            this.retrieveTransac();
         }
     },

     toggleCurrUnSelect(value, id){

         for( var i = 0; i < this.currency.length; i++){
              if ( this.currency[i] === value.name) {
                    //console.log(this.currency);
                    delete this.currency[i];
              }
          }


         this.retrieveTransac();
     },

     mounted() {
         this.retrieveTransac();
     },

    },

  };

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
<style>
    .table{overflow-y:scroll; }
</style>
